

import { useTheme } from '@mui/material/styles';
//import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { KivaSuiteLogo } from '../components/Logo';
import HomeIcon from '@mui/icons-material/Home';
import { Link, useLocation } from "react-router-dom"
import { useAuthDispatch, useAuthState, useGoogleAuth } from '../context/auth/AuthContext';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { useCustomerState } from '../context/customer/CustomerContext';
import { auth0Logout } from '../context/auth/AuthActions';
import { CustomerTree } from '../api/Types';
import SettingsIcon from '@mui/icons-material/Settings';
import CollectionsIcon from '@mui/icons-material/Collections';
import { Grid } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { drawerWidth } from '.';
import { useAuth0 } from '@auth0/auth0-react'
import EuroIcon from '@mui/icons-material/Euro';
import SmartToySharpIcon from '@mui/icons-material/SmartToySharp';
import Search from '@mui/icons-material/Search'
import AssessmentIcon from '@mui/icons-material/Assessment';
import { findActiveServices } from '../utils/helpers';
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import { annotaionQuestionIcon, barChartIcon, dynamicAdsIcon, editIcon, euroCurrencyIcon, homeIcon, lineChartUpIcon, logoutIcon, navMenuIcon, saleIcon, searchIcon, settingsIcon, trendUpIcon } from '../utils/icons';
import { primaryColors } from '../styles/colors';

interface DrawerComponentProps {
  open: any,
  setOpen: any,
  DrawerHeader: any
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));


const DrawerComponent = ({ open, setOpen, DrawerHeader }: DrawerComponentProps) => {
  const findParentRoles = (id: number, customers: CustomerTree[]): string[] | undefined => {
    for (const customer of customers) {
      if (customer.id === id) {
        return customer.roles
      }
      const roles = findParentRoles(id, customer.children)
      if (roles) {
        roles.push(...customer.roles)
        return roles
      }
    }
    return
  }

  const theme = useTheme()
  const auth = useAuthState()
  const { customers, selected } = useCustomerState()
  // const googleAuth = useGoogleAuth()
  const authDispatch = useAuthDispatch()
  const { logout } = useAuth0();
  const location = useLocation()

  const parentRoles = selected ? findParentRoles(selected.id, customers) || [] : []

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const activeServices = selected ? findActiveServices(selected.id, customers) || [] : []

  return (
    <Drawer
      /*
      sx={{
        border:'1px red solid',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}*/
      variant="permanent" // persistent permanent
      anchor="left"
      open={open}

    >
      <DrawerHeader>
        {open && <>
          <Grid container sx={{ width: '140px', alignItems: 'center', justifyItems: 'center' }}>
            <Link to={auth.isSignedIn ? '/' : '/login'} style={{ display: 'flex', alignItems: 'center' }}><KivaSuiteLogo width='124px' /></Link>
          </Grid>

          <IconButton onClick={() => setOpen(!open)}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </>}
        {!open &&
          <IconButton onClick={() => setOpen(!open)}>
            {navMenuIcon(primaryColors[500])}
          </IconButton>}
      </DrawerHeader>
      <Divider />
      <List sx={{maxHeight:"80%", overflowY: 'scroll'}}>

        <ListItem disablePadding component={Link} to="/">
          <ListItemButton sx={location.pathname === '/' ? selectedListItemButtonStyles : {}}>
            <ListItemIcon>
              {homeIcon(primaryColors[500])}
            </ListItemIcon>
            <ListItemText primary={'Home'} />
          </ListItemButton>
        </ListItem>
        {activeServices.indexOf("Analytics") !== -1 &&
          <ListItem disablePadding component={Link} to="/analytics">
            <ListItemButton sx={location.pathname === '/analytics' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {barChartIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Analytics (alfa)"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("SalesPredictor") !== -1 &&
          <ListItem disablePadding component={Link} to="/sales">
            <ListItemButton sx={location.pathname === '/sales' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {lineChartUpIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={'Sales Prediction'} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("KivaAds") !== -1 &&
          <ListItem disablePadding component={Link} to="/adunits">
            <ListItemButton sx={location.pathname === '/adunits' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {euroCurrencyIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={'Ads Reporting'} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("CompetitorAds") !== -1 &&
          <ListItem disablePadding component={Link} to="/competitors/creatives">
            <ListItemButton sx={location.pathname === '/competitors/creatives' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {euroCurrencyIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={'Competitor Ads'} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("KeywordPlanner") !== -1 &&
          <ListItem disablePadding component={Link} to="/keyword-planner">
            <ListItemButton sx={location.pathname === '/keyword-planner' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {searchIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={'Keyword Planner'} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("KivaAdAutomations") !== -1 &&
          <ListItem disablePadding component={Link} to="/meta-ad-automations">
            <ListItemButton sx={location.pathname === '/meta-ad-automations' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {trendUpIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Meta Post Booster"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("KivaPostBooster") !== -1 &&
          <ListItem disablePadding component={Link} to="/post-booster">
            <ListItemButton sx={location.pathname === '/post-booster' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {trendUpIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Post Booster"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("AdTemplates") !== -1 &&
          <ListItem disablePadding component={Link} to="/ad-templates">
            <ListItemButton sx={location.pathname === '/ad-templates' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {editIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Ad Templates"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("ManagedAds") !== -1 &&
          <ListItem disablePadding component={Link} to="/managed-ads">
            <ListItemButton sx={location.pathname === '/managed-ads' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {dynamicAdsIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Dynamic Ads"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("AdsSpendReporting") !== -1 &&
          <ListItem disablePadding component={Link} to="/ads-spend-reporting">
            <ListItemButton sx={location.pathname === '/ads-spend-reporting' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {euroCurrencyIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Ads Spend"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("KivaAnalytics") !== -1 &&
          <ListItem disablePadding component={Link} to="/kiva-analytics">
            <ListItemButton sx={location.pathname === '/kiva-analytics' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {barChartIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Kiva Analytics"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("DigitalMarketingAssistant") !== -1 &&
          <ListItem disablePadding component={Link} to="/digital-marketing-assistant">
            <ListItemButton sx={location.pathname === '/digital-marketing-assistant' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {annotaionQuestionIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"MAIA"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("KeywordAnalysis") !== -1 &&
          <ListItem disablePadding component={Link} to="/keyword-analysis">
            <ListItemButton sx={location.pathname === '/keyword-analysis' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {searchIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Keyword Analysis"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("KivaCss") !== -1 &&
          <ListItem disablePadding component={Link} to="/kiva-css">
            <ListItemButton sx={location.pathname === '/kiva-css' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {saleIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Kiva CSS"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("CustomAssistant") !== -1 &&
          <ListItem disablePadding component={Link} to="/custom-assistant">
            <ListItemButton sx={location.pathname === '/custom-assistant' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {saleIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Custom assistant"} />
            </ListItemButton>
          </ListItem>
        }
        {activeServices.indexOf("Files") !== -1 &&
          <ListItem disablePadding component={Link} to="/files">
            <ListItemButton sx={location.pathname === '/files' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {saleIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Files"} />
            </ListItemButton>
          </ListItem>
        }
        {parentRoles.indexOf("admin") !== -1 &&
          <ListItem disablePadding component={Link} to="/admin">
            <ListItemButton sx={location.pathname === '/admin' ? selectedListItemButtonStyles : {}}>
              <ListItemIcon>
                {settingsIcon(primaryColors[500])}
              </ListItemIcon>
              <ListItemText primary={"Admin"} />
            </ListItemButton>
          </ListItem>
        }

      </List>
      <Divider />

      <List sx={{ position: 'absolute', bottom: 0, width: '100%' }}>
        <ListItem disablePadding>
          <ListItemButton onClick={() => auth0Logout(logout, authDispatch)}>
            <ListItemIcon>
              {logoutIcon(primaryColors[500])}
            </ListItemIcon>
            <ListItemText primary={'Logout'} />
          </ListItemButton>
        </ListItem>
      </List>

    </Drawer>
  )
}

const selectedListItemButtonStyles = {
  backgroundColor: `${primaryColors[100]} !important`
}

export default DrawerComponent