

import { Button, Checkbox, FormControlLabel, FormGroup, Grid, TextField, Typography } from '@mui/material';
import { apiGetSpendsSettings, apiPutSpendSettings, apiRemoveSpendSettings } from '../../api/Customer';
import { useEffect, useState } from 'react';
import { SpendsSettings } from '../../api/Types';
import { LoadingModal } from '../Modal';
import { AlertAction, AlertSeverity } from '../../context/alert/AlertTypes';
import { debouncePromise } from '../../utils/Generators';
import styled from 'styled-components';
import { setAlert } from '../../context/alert/AlertAction';

const DeleteContainer = styled.div`
    display:grid;
    grid-template-columns:1fr 1fr;
    column-gap:5px;
    position:absolute;
    top:5em;
    right:10px;
`

const textFieldTextStyles = {
  mb: 0
}

const gridItemStyles = {
  mt: 0,
  mb: 0
}

export type SpendsAdminProps = {
  customerId: number
  alertDispatch: React.Dispatch<AlertAction>
}

const debouncedApiPutSpendSettings = debouncePromise(apiPutSpendSettings)

const EmptySettings: SpendsSettings = {
  host: "",
  username: "",
  directory: "",
  publicKey: "",
  privateKey: "",
  channels: []
}

export const SpendsAdmin = (props: SpendsAdminProps) => {
  const { customerId, alertDispatch } = props
  const [loading, setLoading] = useState(true)
  const [settings, setSettings] = useState<SpendsSettings>(EmptySettings)
  const [requiresUpdate, setRequiresUpdate] = useState<boolean>(false)

  useEffect(() => {
    apiGetSpendsSettings(customerId).then((response) => {
      setLoading(false)
      if (response) {
        setSettings(response)
      } else {
        setSettings(EmptySettings)
      }
    })
  }, [customerId])

  useEffect(() => {
    if (requiresUpdate) {
      setRequiresUpdate(false)
      debouncedApiPutSpendSettings(customerId, settings).catch(() => {

      })
    }
  }, [requiresUpdate])

  function updateSettings(settings: SpendsSettings) {
    setSettings(settings)
    setRequiresUpdate(true)
  }

  function removeSpendSettings() {
    apiRemoveSpendSettings(customerId).then(() => {
      setSettings(EmptySettings)
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Success, message: "Removing settings succeeded.", duration: null }))
    }).catch(() => {
      alertDispatch(setAlert({ open: true, severity: AlertSeverity.Error, message: "Removing settings failed.", duration: null }))
    })
  }

  function handleChannelChange(ev: React.SyntheticEvent<Element, Event>, checked: boolean) {
    const value = (ev.target as any).value
    setSettings({ ...settings, channels: (settings.channels || []).filter(c => c !== value).concat(checked ? [value] : []) })
    setRequiresUpdate(true)
  }

  return (<Grid container>
    <Grid item xs={12} sx={gridItemStyles}>
      {loading ? <LoadingModal /> :
        <Grid container sx={{ maxWidth: 600 }} rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <DeleteContainer>
            <Button color="error" variant="contained" onClick={() => removeSpendSettings()}>Delete</Button>
          </DeleteContainer>
          <Grid item xs={12} sx={gridItemStyles}>
            SFTP Export Settings
          </Grid>
          <Grid item xs={12} sx={gridItemStyles}>
            <FormGroup>
              <FormControlLabel onChange={handleChannelChange} control={<Checkbox value="googleAds" checked={settings.channels.indexOf("googleAds") !== -1} />} label="Google Ads" />
              <FormControlLabel onChange={handleChannelChange} control={<Checkbox value="displayVideo" checked={settings.channels.indexOf("displayVideo") !== -1} />} label="Display & Video" />
              <FormControlLabel onChange={handleChannelChange} control={<Checkbox value="bingAds" checked={settings.channels.indexOf("bingAds") !== -1} />} label="Bing Ads" />
              <FormControlLabel onChange={handleChannelChange} control={<Checkbox value="metaAds" checked={settings.channels.indexOf("metaAds") !== -1} />} label="Meta Ads" />
              <FormControlLabel onChange={handleChannelChange} control={<Checkbox value="linkedInAds" checked={settings.channels.indexOf("linkedInAds") !== -1} />} label="LinkedIn Ads" />
            </FormGroup>
          </Grid>
          <Grid item xs={6} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Host</Typography>
            <TextField fullWidth size='small' variant="outlined"
              value={settings.host}
              onChange={(ev) => updateSettings({ ...settings, host: ev.target.value })} />
          </Grid>
          <Grid item xs={6} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Username</Typography>
            <TextField fullWidth size='small' variant="outlined"
              value={settings.username}
              onChange={(ev) => updateSettings({ ...settings, username: ev.target.value })} />
          </Grid>
          <Grid item xs={8} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Directory</Typography>
            <TextField fullWidth size='small' variant="outlined"
              value={settings.directory}
              onChange={(ev) => updateSettings({ ...settings, directory: ev.target.value })} />
          </Grid>
          <Grid item xs={12} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Public key</Typography>
            <TextField rows={4} fullWidth size='small' variant="outlined"
              multiline
              value={settings.publicKey}
              onChange={(ev) => updateSettings({ ...settings, publicKey: ev.target.value })} />
          </Grid>
          <Grid item xs={12} sx={gridItemStyles}>
            <Typography sx={textFieldTextStyles}>Private key</Typography>
            <TextField rows={8} fullWidth size='small' variant="outlined"
              multiline
              value={settings.privateKey}
              onChange={(ev) => updateSettings({ ...settings, privateKey: ev.target.value })} />
          </Grid>
        </Grid>}
    </Grid>
  </Grid>)
}
