import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import GlobalStyles from '@mui/material/GlobalStyles';
import Container from '@mui/material/Container';
import CustomAlert from '../CustomAlert';
import { KivaSuiteLogo } from '../Logo';
import { Link } from 'react-router-dom';
import { getDeletedUserAssociatedWithCode } from '../../api/Marketing';
import moment from 'moment';
import { desktop_p2 } from '../../styles/textStyles';

const validateCode = (code: string) => /^[a-f0-9]{16}$/i.test(code); // Match 16-character hex

const UserDataDeletionPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const [code, setCode] = useState("")
  const [deletedUser, setDeletedUser] = useState<{facebookUserId: string, deletionStatus: string, deletionDate: string, confirmationCode: string} | null>(null)

  useEffect(() => {
    const code = urlParams.get("code");
    code && setCode(code)
  }, [urlParams])

  useEffect(() => {
    if(code && validateCode(code)){
      getDeletedUserAssociatedWithCode(code).then(res => {
        if(res.deletedUser){
          setDeletedUser(res.deletedUser)
        }
      }).catch((_err) => null)
    }
  }, [code]);

  return (
    <React.Fragment>
      <GlobalStyles styles={{ ul: { margin: 0, padding: 0, listStyle: 'none' } }} />
      <CssBaseline />
      <CustomAlert />
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: '#fff', borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <Box sx={{ display: 'grid', alignContent: 'center', alignItems: 'center', flexGrow: 1 }}>
            <Link to={'/login'} style={{ display: 'flex', alignItems: 'center'}}><KivaSuiteLogo width='124px' /></Link>
          </Box>
        </Toolbar>
      </AppBar>

      <Container disableGutters maxWidth="md" component="main" sx={{ pt: 6, pb: 2 }}>
        <Box sx={{ display: 'grid', margin: 'auto', alignContent: 'center', justifyContent: 'center', flexGrow: 1, mb: 2 }}>
          <KivaSuiteLogo width='180px' />
        </Box>
        <Typography variant="h6" align="center" color="text.secondary" component="p">
          Analytics, digital marketing and comparison shopping services
        </Typography>
        <Box sx={{ display: 'grid', margin: 'auto', alignItems: 'center', justifyContent: 'center', flexGrow: 1, mb: 2 }}>
            <Typography variant="h6" sx={{ mb: 2, mt: 2, textAlign: 'center'}}>User Data Deletion Status</Typography>
            {!deletedUser && <Typography sx={typographyStyle}>Invalid confirmation code: {code}</Typography>}
            
            {deletedUser && <> <Typography sx={typographyStyle}>Confirmation code: {code}</Typography>
            <Typography sx={typographyStyle}>Status: <b style={{color: 'green'}}>{deletedUser.deletionStatus}</b> </Typography>
            <Typography sx={typographyStyle}>Deletion date: {moment(deletedUser.deletionDate).format("DD.MM.YYYY")} </Typography>
            <Typography sx={{...typographyStyle, maxWidth: 680}}>The Facebook user data associated with confirmation code ({code}) has been successfully deleted.</Typography></>}
        </Box>
      </Container>

      {/* Footer */}
      <Container
        maxWidth="sm"
        component="footer"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          alignContent: 'center',
          margin: 'auto',
          alignItems: 'center',
          mt: 2,
          py: [3, 2],
        }}
      >
        <Typography variant="body2" color="text.secondary" align="center">
          {'Copyright © '}
          Kiva Suite{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
};


const typographyStyle = {
  ...desktop_p2, 
  mb: 1, 
  textAlign: 'center'
}

export default UserDataDeletionPage;
