import axios from "axios";
import { AdTemplate } from "../components/AdTemplateView/Types";
import {
  AdUnitSettings, ConversionPredictions, CustomerTree, UsersResponse,
  KeywordCategoriesResponse, KeywordVolumesResponse, MetaSettings,
  PageKeywordStatResponse, SearchConsoleSettings, NotificationRulesResponse, NotificationRule, CustomerService, ServiceConfiguration,
  ConversionPredictionList, ManagedAd, ManagedAdType, ManageAdDataMapping, ManagedAdDataSource, ManagedAdInstance,
  ManagedAdPlatform, ManagedAdPlatformTypes, ManagedAdExitEvent, ManagedAdRule, ManagedAdUpdate, BingAdsSettings,
  NewUser, PredictionScenario, GoogleAnalyticsSettings, SpendsSettings, AdTemplateFile, LinkedInAdsSettings, ReadpeakAdsSettings, YoutubeContentSettings, ManagedAdTypeDef, ManagedAdFile, MetaCampaign, MetaAdSet, SlackSettings, DatasetSettings, apiRootUrl,
  FileTree,
  IdNameObj
} from "./Types";
import { deleteApi, deleteApiBool, deleteBodyApi, getApi, getApiText, getBase64Api, patchApi, postApi, postApiForm, putApi, putApiBool } from "./Utils";
import { GoogleAdsSettings } from "../context/admin/AdminTypes";
import { CompetitorSettings } from "../context/competitors/CompetitorTypes";

axios.defaults.withCredentials = true;

export const apiGetCustomers = async (): Promise<CustomerTree[] | undefined> => {
  return await getApi("customer") as CustomerTree[]
}

export const apiGetConversionPredictions = async (customerId: number, tag: string): Promise<ConversionPredictions | undefined> => {
  return await getApi(`conversion/customer/${customerId}/predictions/${encodeURIComponent(tag)}`)
}

export const apiGetConversionPredictionList = async (customerId: number): Promise<ConversionPredictionList | undefined> => {
  return await getApi(`conversion/customer/${customerId}/list`)
}

export const apiPutAdUnitConfiguration = async (customerId: number, adunitSettings: AdUnitSettings): Promise<boolean> => {
  return await putApiBool(`customer/${customerId}/config/adunits`, adunitSettings)
}

export const apiGetAdUnitsSettings = async (customerId: number): Promise<AdUnitSettings> => {
  return await getApi(`customer/${customerId}/config/adunits`)
}

export const apiGetSearchConsoleSettings = async (customerId: number): Promise<SearchConsoleSettings> => {
  return await getApi(`customer/${customerId}/config/searchConsole`)
}

export const apiGetGoogleAdsSettings = async (customerId: number): Promise<GoogleAdsSettings> => {
  return await getApi(`customer/${customerId}/config/googleAds`)
}

export const apiGetBingAdsSettings = async (customerId: number): Promise<BingAdsSettings> => {
  return await getApi(`customer/${customerId}/config/bingAds`)
}

export const apiRemoveBingAdsSettings = async (customerId: number): Promise<void> => {
  return await deleteApi(`customer/${customerId}/config/bingAds`)
}

export const apiGetGoogleAnalyticsSettings = async (customerId: number): Promise<GoogleAnalyticsSettings> => {
  return await getApi(`customer/${customerId}/config/googleAnalytics`)
}

export const apiGetMetaSettings = async (customerId: number): Promise<MetaSettings> => {
  return await getApi(`customer/${customerId}/config/meta`)
}

export const apiPutMetaSettings = async (customerId: number, notifyAuthExpirationDays: number): Promise<void> => {
  return await putApi(`customer/${customerId}/config/meta`, { notifyAuthExpirationDays })
}

export const apiSaveMetaCompetitorSettings = async (customerId: number, facebookPages: IdNameObj[]): Promise<{facebookPages: IdNameObj[]}> => {
  return await putApi(`customer/${customerId}/config/metaCompetitorSettings`, { facebookPages })
}

export const apiSaveGoogleCompetitorSettings = async (customerId: number, advertisers: IdNameObj[]): Promise<{advertisers: IdNameObj[]}> => {
  return await putApi(`customer/${customerId}/config/googleCompetitorSettings`, { advertisers })
}

export const apiGetCompetitorSettings = async (customerId: number): Promise<CompetitorSettings> => {
  return await getApi(`customer/${customerId}/config/competitorSettings`)
}

export const apiClearMetaSettings = async (customerId: number): Promise<void> => {
  return await deleteApi(`customer/${customerId}/config/meta`)
}

export const apiClearGoogleAdsSettings = async (customerId: number): Promise<void> => {
  return await deleteApi(`customer/${customerId}/config/googleAds`)
}

export const apiGetPageKeywordStat = async (customerId: number, startDate: string, endDate: string): Promise<PageKeywordStatResponse> => {
  return await postApi(`customer/${customerId}/keyword/stats`, {
    startDate,
    endDate
  })
}

export const apiGetPageKeywordVolumes = async (customerId: number, keywords: string[]): Promise<KeywordVolumesResponse> => {
  return await postApi(`customer/${customerId}/keyword/volumes`, {
    keywords
  })
}

export const apiGetKeywordCategories = async (customerId: number): Promise<KeywordCategoriesResponse> => {
  return await getApi(`customer/${customerId}/keyword/categories`)
}

export const apiAddKeywordCategory = async (customerId: number, category: string): Promise<KeywordCategoriesResponse> => {
  return await postApi(`customer/${customerId}/keyword/category`, { category })
}

export const apiRemoveKeywordCategory = async (customerId: number, category: string): Promise<KeywordCategoriesResponse> => {
  return await deleteBodyApi(`customer/${customerId}/keyword/category`, { category })
}

export const apiGetUsers = async (customerId: string): Promise<UsersResponse> => {
  return await getApi(`customer/${customerId}/users`)
}

export const apiPutNotificationRules = async (customerId: string, rules: NotificationRule[]): Promise<void> => {
  return await putApi(`customer/${customerId}/rules/notifications`, rules)
}

export const apiGetNotificationRules = async (customerId: string): Promise<NotificationRulesResponse> => {
  return await getApi(`customer/${customerId}/rules/notifications`)
}

export const apiSetHiddenCategoryKeyword = async (customerId: number, categories: { category: string, keyword: string }[], hidden: boolean): Promise<KeywordCategoriesResponse> => {
  return await putApi(`customer/${customerId}/keyword/categories`, { categories, hidden })
}

export const apiStartDv360Login = async (customerId: number): Promise<Blob | undefined> => {
  const response = await fetch(`api/creative/customer/${customerId}/dv360/login`, {
    method: "POST",
    credentials: 'include'
  })
  if (response.status >= 300) {
    return
  }
  return await response.blob()
}

export const apiPutDv360CaptchaText = async (customerId: number, captcha: string): Promise<void> => {
  return await putApi(`creative/customer/${customerId}/dv360/captcha/${captcha}`, {})
}

export const apiPutDv360OneTimeText = async (customerId: number, onetime: string): Promise<void> => {
  await putApi(`creative/customer/${customerId}/dv360/onetime/${onetime}`, {})
}

export const apiGetCustomerServices = async (customerId: number): Promise<CustomerService[]> => {
  return getApi(`service/customer/${customerId}`)
}

export const apiSetServiceActivation = async (customerId: number, type: string, active: boolean): Promise<void> => {
  await putApi(`service/customer/${customerId}/${active ? 'activate' : 'deactivate'}/${type}`, {})
}

export const apiSetCustomerParent = async (customerId: number, parentId: string): Promise<CustomerTree[]> => {
  return await putApi(`customer/${customerId}/parent`, { parentId })
}

export const apiUpdateServiceConfiguration = async (customerId: number, type: string, configuration: ServiceConfiguration): Promise<void> => {
  return await putApi(`service/customer/${customerId}/configuration/${type}`, configuration)
}

export const apiCreateCustomer = async (parentId: number, name: string): Promise<void> => {
  await postApi(`customer/${parentId}`, { name })
}

export const apiDeleteCustomer = async (id: number): Promise<void> => {
  await deleteApi(`customer/${id}`)
}

export const apiUpdateCustomerName = async (customerId: number, name: string): Promise<void> => {
  await putApi(`customer/${customerId}/name`, { name })
}

export const apiGetManagedAds = async (customerId: number): Promise<ManagedAd[]> => {
  return await getApi(`managed/customer/${customerId}/creative`)
}

export const apiGetManagedAdContent = async (url: string): Promise<string> => {
  return await getApiText(url)
}

export const apiRefreshManagedAdToPlatforms = async (customerId: number, ad: ManagedAd): Promise<string> => {
  if (ad.synchronize) {
    await apiSetManagedAdSynchronize(customerId, ad.creativeId, ad.synchronize)
  }
  return await apiGetManagedAdContent(ad.previewUrl)
}

export const apiCreateManageAd = async (customerId: number, params: {
  createType: "custom" | "template",
  type: ManagedAdType,
  name: string, entryPoint?: string, templateId?: string
}): Promise<ManagedAd> => {
  return await postApi(`managed/customer/${customerId}/creative`, params)
}

export const apiGetCustomerDataSources = async (customerId: string): Promise<ManagedAdDataSource[]> => {
  return await getApi(`customer/${customerId}/data-sources`)
}

export const apiSetManagedAdDataMappings = async (customerId: number, creativeId: string,
  dms: ManageAdDataMapping[], callback: () => void): Promise<void> => {
  await putApi(`managed/customer/${customerId}/creative/${creativeId}/data-mappings`, dms)
  callback()
}


export const apiGetManagedVideoPreview = async (customerId: number, creativeId: string, width: number, height: number): Promise<{ imageUrl: string, videoUrl: string }> => {
  return await postApi(`managed/customer/${customerId}/creative/${creativeId}/video-preview`, {
    width, height
  })
}

export const apiUploadManagedAdMaterial = async (customerId: number, creativeId: string,
  formData: FormData): Promise<ManagedAdInstance[]> => {
  return await postApiForm(`managed/customer/${customerId}/creative/${creativeId}/materials`, formData)
}

export const apiSetManagedAdSynchronize = async (customerId: number, creativeId: string,
  synchronize: boolean): Promise<void> => {
  await putApi(`managed/customer/${customerId}/creative/${creativeId}/synchronize`, { synchronize })
}

export const apiAddManagedAdPlatform = async (customerId: number, creativeId: string, type: ManagedAdPlatformTypes): Promise<ManagedAdPlatform> => {
  return await postApi(`managed/customer/${customerId}/creative/${creativeId}/platform/${type}`, {})
}

export const apiPutManagedAdPlatform = async (customerId: number, creativeId: string, platform: ManagedAdPlatform): Promise<ManagedAdPlatform> => {
  return await putApi(`managed/customer/${customerId}/creative/${creativeId}/platform/${platform.id}`, platform)
}

export const apiDeleteManagedAdPlatform = async (customerId: number, creativeId: string, platformId: string): Promise<void> => {
  await deleteApi(`managed/customer/${customerId}/creative/${creativeId}/platform/${platformId}`, {})
}

export const apiGetManagedAdPlatforms = async (customerId: number, creativeId: string): Promise<ManagedAdPlatform[]> => {
  return await getApi(`managed/customer/${customerId}/creative/${creativeId}/platforms`)
}

export const apiGetManagedAdExitEvents = async (customerId: number, creativeId: string): Promise<ManagedAdExitEvent[]> => {
  return await getApi(`managed/customer/${customerId}/creative/${creativeId}/exit-events`)
}

export const apiSetManagedAdExitEvents = async (customerId: number, creativeId: string,
  exitEvents: ManagedAdExitEvent[]): Promise<void> => {
  await putApi(`managed/customer/${customerId}/creative/${creativeId}/exit-events`, exitEvents)
}

export const apiDeleteManagedAd = async (customerId: number, creativeId: string): Promise<void> => {
  await deleteApi(`managed/customer/${customerId}/creative/${creativeId}`)
}

export const apiGetMetaCampaigns = async (customerId: number): Promise<MetaCampaign[]> => {
  return await getApi(`managed/customer/${customerId}/meta/campaigns`)
}

export const apiGetMetaAdsets = async (customerId: number, campaignId: string): Promise<MetaAdSet[]> => {
  return await getApi(`managed/customer/${customerId}/meta/adsets/${campaignId}`)
}

export const apiGetManagedAdFiles = async (customerId: number, creativeId: string): Promise<ManagedAdFile[]> => {
  return await getApi(`managed/customer/${customerId}/creative/${creativeId}/files`)
}

export const apiDeleteManagedAdInstance = async (customerId: number, creativeId: string, instance: ManagedAdInstance): Promise<boolean> => {
  return await deleteApiBool(`managed/customer/${customerId}/creative/${creativeId}/instance`, instance)
}

export const apiAdManagedAdTypeFromHtml = async (customerId: number, creativeId: string, type: ManagedAdType, durationInMs: number): Promise<ManagedAdTypeDef[]> => {
  return await postApi(`managed/customer/${customerId}/creative/${creativeId}/type`, { type, durationInMs })
}

export const apiSetManagedAdName = async (customerId: number, creativeId: string, name: string): Promise<void> => {
  await patchApi(`managed/customer/${customerId}/creative/${creativeId}`, { name })
}

export const apiGetManagedAdDataMappings = async (customerId: number, creativeId: string): Promise<ManageAdDataMapping[]> => {
  return await getApi(`managed/customer/${customerId}/creative/${creativeId}/data-mappings`)
}

export const apiSetManagedAdRules = async (customerId: number, creativeId: string, rules: ManagedAdRule[]): Promise<void> => {
  await patchApi(`managed/customer/${customerId}/creative/${creativeId}`, { rules })
}

export const apiSetUpdates = async (customerId: number, creativeId: string, updates: ManagedAdUpdate): Promise<void> => {
  await patchApi(`managed/customer/${customerId}/creative/${creativeId}`, { updates })
}

export const apiCreateNewUser = async (customerId: number, name: string, email: string): Promise<any> => {
  return await postApi(`customer/${customerId}/create-new-user`, { name, email })
}


export const apiGetPredictionScenarios = async (customerId: number): Promise<PredictionScenario[]> => {
  return await getApi(`customer/${customerId}/prediction/scenarios`)
}

export const apiGetDefaultPredictionSpends = async (customerId: number): Promise<number[]> => {
  return await getApi(`customer/${customerId}/prediction/spends/default`)
}

export const apiPutPredictionScenario = async (customerId: number, scenario: PredictionScenario): Promise<void> => {
  await putApi<void>(`customer/${customerId}/prediction/scenario`, scenario)
}

export const apiDeletePredictionScenario = async (customerId: number, scenarioId: string): Promise<void> => {
  await deleteApi<void>(`customer/${customerId}/prediction/scenario/${scenarioId}`)
}

export const apiGetAdTemplates = async (customerId: number): Promise<AdTemplate[]> => {
  return await getApi<AdTemplate[]>(`ad-templates/customer/${customerId}`)
}

export const apiCreateAdTemplate = async (customerId: number, name: string, sourceTemplateId?: string): Promise<AdTemplate> => {
  return await postApi(`ad-templates/customer/${customerId}`, { name, sourceTemplateId })
}

export const apiUpdateAdTemplate = async (customerId: number, adTemplate: AdTemplate): Promise<AdTemplate> => {
  return await patchApi(`ad-templates/customer/${customerId}`, adTemplate)
}

export const apiDeleteAdTemplate = async (customerId: number, templateId: string): Promise<void> => {
  await deleteApi(`ad-templates/customer/${customerId}/${templateId}`)
}

export const apiUploadAdTemplateFile = async (customerId: number, templateId: string, name: string, formData: FormData): Promise<AdTemplate> => {
  return await postApiForm(`ad-templates/customer/${customerId}/${templateId}/file/${encodeURIComponent(name)}`, formData)
}

export const apiReplaceManagedAdFile = async (customerId: number, creativeId: string, name: string, formData: FormData): Promise<void> => {
  await postApi(`managed/customer/${customerId}/creative/${creativeId}/file/${encodeURIComponent(name)}`, formData)
}

export const apiPatchAdTemplateFile = async (customerId: number, templateId: string, fileId: string,
  searchText: string, newText: string): Promise<{ content: string }> => {
  return await patchApi<{ content: string }>(`ad-templates/customer/${customerId}/${templateId}/file/${fileId}`, {
    searchText,
    newText
  })
}

export const apiUploadCustomerFile = async (customerId: number, parentId: string | null, name: string, formData: FormData): Promise<FileTree> => {
  return await postApiForm(`customer/${customerId}/files/${encodeURIComponent(name)}/${parentId}`, formData)
}

export const apiDeleteCustomerFile = async (customerId: number, fileId: string): Promise<FileTree> => {
  return await deleteApi(`customer/${customerId}/files/${fileId}`)
}

export const apiCreateCustomerFolder = async (name: string, customerId: number, parentId: string): Promise<FileTree> => {
  return await postApi(`customer/${customerId}/folder/${parentId}`, {name})
}

export const apiUpdateCustomerFile = async (customerId: number, fileTree: FileTree): Promise<FileTree> => {
  return await putApi(`customer/${customerId}/file`, fileTree)
}

export const apiGetCustomerFiles = async (customerId: number): Promise<FileTree> => {
  return await getApi(`customer/${customerId}/files`)
}

export const getCustomerFileUrl = (customerId: number, id: string): string => {
  return apiRootUrl + `/customer/${customerId}/file/${id}/binary`
}

export const getCustomerFileBase64 = async (customerId: number, id: string): Promise<string> => {
  return await getBase64Api(`customer/${customerId}/file/${id}/base64`)
}

export const apiDeleteAdTemplateFile = async (customerId: number, templateId: string, fileId: string): Promise<AdTemplateFile[]> => {
  return await deleteApi<AdTemplateFile[]>(`ad-templates/customer/${customerId}/${templateId}/file/${fileId}`)
}

export const apiPostPredictions = async (customerId: number, startDate: string, scenario?: PredictionScenario): Promise<ConversionPredictions | undefined> => {
  return await postApi<ConversionPredictions>(`customer/${customerId}/prediction/predict`, {
    startDate,
    weeklyBudgets: scenario?.weeklyBudgets,
    weeklyGoals: scenario?.weeklyGoals
  }) as ConversionPredictions
}

export const apiGetSpendsSettings = async (customerId: number): Promise<SpendsSettings> => {
  return await getApi(`customer/${customerId}/config/spends`)
}

export const apiPutSpendSettings = async (customerId: number, settings: SpendsSettings): Promise<SpendsSettings> => {
  return await putApi(`customer/${customerId}/config/spends`, settings)
}

export const apiRemoveSpendSettings = async (customerId: number): Promise<SpendsSettings> => {
  return await deleteApi(`customer/${customerId}/config/spends`)
}

export const apiGetLinkedInSettings = async (customerId: string): Promise<LinkedInAdsSettings> => {
  return await getApi(`customer/${customerId}/config/linkedInAds`)
}

export const apiRemoveLinkedInSettings = async (customerId: string): Promise<void> => {
  return await deleteApi(`customer/${customerId}/config/linkedInAds`)
}

export const apiGetReadpeakSettings = async (customerId: string): Promise<ReadpeakAdsSettings> => {
  return await getApi(`customer/${customerId}/config/readpeakAds`)
}

export const apiGetYoutubeContentSettings = async (customerId: string): Promise<YoutubeContentSettings> => {
  return await getApi(`customer/${customerId}/config/youtubeContent`)
}

export const apiGetSlackSettings = async (customerId: string): Promise<SlackSettings> => {
  return await getApi(`customer/${customerId}/config/slack`)
}

export type GoogleSheetAdTemplate = {
  url?: string,
  redirectUrl?: string
}

export type GoogleSheetAdParams = {
  redirectUrl?: string
  params: string[]
}

export const apiCreateGoogleSheetAdTemplate = async (customerId: number, params: {
  ads: ManagedAd[]
}): Promise<GoogleSheetAdTemplate> => {
  return await postApi(`managed/customer/${customerId}/exportsheet`, params)
}

export const apiGetGoogleSheetAds = async (customerId: number): Promise<GoogleSheetAdParams> => {
  return await getApi(`managed/customer/${customerId}/sheet`)
}

export const apiUpdateManagedAdFromTemplate = async (customerId: number, creativeId: string): Promise<ManagedAd> => {
  return await patchApi(`managed/customer/${customerId}/creative/${creativeId}/template`, {})
}

export const apiGetDatasetSettings = async (customerId: string): Promise<DatasetSettings> => {
  return await getApi(`customer/${customerId}/config/datasets`)
}

export const apiRemoveDatasetSettings = async (customerId: string, projectId: string, datasetId: string): Promise<DatasetSettings> => {
  return await deleteBodyApi(`customer/${customerId}/config/datasets`, { projectId, datasetId })
}

/**
 * @desc used to save selected assets into db
 * @param customerId
 * @param assets
 */
export const apiSaveSelectedAssets = async (customerId: number, assets: any) => {
  try {
    const res = await axios.post(`${apiRootUrl}/customer/${customerId}/save-assets`, assets);
    return res.data;
  } catch (err: any) {
    return err.response.data
  }
};


/**
 * @desc used to save google ads settings into the db
 * @param customerId
 * @param settings
 */
export const apiSaveGoogleAdsSettings = async (customerId: number, settings: GoogleAdsSettings) => {
  try {
    const res = await axios.post(`${apiRootUrl}/customer/${customerId}/config/googleAds/saveSettings`, settings);
    return res.data;
  } catch (err: any) {
    return err.response.data
  }
};

/**
 * @desc used to get saved assets from the db
 * @param customerId
 */
export const apiGetSavedAssets = async (customerId: number) => {
  try {
    const res = await axios.get(`${apiRootUrl}/customer/${customerId}/saved-assets`);
    return res.data;
  } catch (err: any) {
    console.log("apiGetSavedAssets error:", err)
    return err.response.data
  }
};
