import { Box, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { desktop_h1 } from '../../styles/textStyles'
import DateRangePickerMui from '../DateRangePickerMui'
import CircularProgressLoading from '../CircularProgressLoading'
import KeywordSearch from '../Ads/MoreFilters/KeywordSearch'
import GridViewItemsCountToggle from '../GridViewItemsCountToggle'
import { useCustomerState } from '../../context/customer/CustomerContext'
import { useCompetitorStateValue } from '../../context/competitors/CompetitorContext'
import { setCompetitorAdUnits, setCompetitorFacebookPages, setCompetitorSettings, setCompetitorState, setGoogleAdvertisers } from '../../context/competitors/CompetitorActions'
import { apiGetCompetitorAds } from '../../api/Competitors'
import dayjs from 'dayjs'
import { DateRange } from '@mui/x-date-pickers-pro/internals/models'
import { CompetitorCreativePreview } from './CompetitorCreativePreview'
import { CompetitorAdStatus, CompetitorCreative, CompetitorCreativeResponse, CompetitorSettings, MetaCompetitorAdType } from '../../context/competitors/CompetitorTypes'
import Channels from './Channels'
import GoogleAdvertisersFilter from './GoogleAdvertisersFilter'
import { apiGetCompetitorSettings } from '../../api/Customer'
import CompetitorFacebookPagesFilter from './CompetitorFacebookPagesFilter'

const CompetitorCreatives = () => {

  const customerState = useCustomerState()
  const { selected } = customerState
  const [{ competitorAdUnits, competitorSettings }, competitorDispatch] = useCompetitorStateValue()
  const { startDate, endDate, creatives, searchKeyword, status, loading, gridViewItemsCount, facebookPageIds, adType, categories, googleAdvertiserIds } = competitorAdUnits
  
  useEffect(() => {
    if(selected){
        apiGetCompetitorSettings(selected?.id).then((response: CompetitorSettings) => {
            const metaIds = response.meta?.facebookPages?.map(i => i.id) || []
            const googleIds = response.google?.advertisers?.map(i => i.id) || []
            competitorDispatch(setCompetitorState({
              competitorSettings: response,
              competitorAdUnits: { ...competitorAdUnits, 
                loading: true,
                facebookPageIds: metaIds,
                googleAdvertiserIds: googleIds 
              }
            }))
            return {metaIds, googleIds}
        }).then(({metaIds, googleIds}) => {
          apiGetCompetitorAds(selected.id, {
            searchKeyword,
            startDate,
            endDate,
            status,
            adType,
            facebookPageIds: metaIds,
            googleAdvertiserIds: googleIds,
            categories
          }).then((resp: CompetitorCreativeResponse) => {
            competitorDispatch(setCompetitorAdUnits({
              ...competitorAdUnits,
              creatives: resp, 
              facebookPageIds: metaIds,
              googleAdvertiserIds: googleIds,
              loading: false
            }))
          }).catch(() =>  {
            competitorDispatch(setCompetitorAdUnits({
              ...competitorAdUnits,
              creatives: { creatives: creatives.creatives }, loading: false
            }))
          });
          
        })
    }
  }, [selected])
  
 
  const getCompetitorAds = () => {
    if (selected) {
      competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, loading: true }))
      apiGetCompetitorAds(selected!.id, {
        searchKeyword,
        startDate: startDate, endDate: endDate, status, 
        facebookPageIds: facebookPageIds, 
        googleAdvertiserIds: googleAdvertiserIds, 
        adType,
        categories
      }).then((resp: CompetitorCreativeResponse) => {
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: resp, loading: false
        }))
      }).catch(() => {
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: { creatives: creatives.creatives }, loading: false
        }))

      })
    }
  }

  const getCompetitorAdsUsingParams = (params: any) => {
    if (selected) {
      competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, loading: true, startDate: params.startDate, endDate: params.endDate, searchKeyword: params.searchKeyword, status: params.status, adType: params.adType, facebookPageIds: params.facebookPageIds, googleAdvertiserIds: params.googleAdvertiserIds }))
      apiGetCompetitorAds(selected.id, params).then((resp: CompetitorCreativeResponse) => {
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: resp, 
          startDate: params.startDate, endDate: params.endDate, 
          searchKeyword: params.searchKeyword, status: params.status, adType: params.adType,
          facebookPageIds: params.facebookPageIds,
          googleAdvertiserIds: params.googleAdvertiserIds,
          loading: false
        }))
      }).catch(() => {
        competitorDispatch(setCompetitorAdUnits({
          ...competitorAdUnits,
          creatives: { creatives: creatives.creatives }, loading: false
        }))

      })
    }
  }

  const handleDateRangeChange = (dateRange: DateRange<dayjs.Dayjs>) => {
    if (dateRange && dateRange[0] && dateRange[1]) {
      getCompetitorAdsUsingParams({
          searchKeyword,
          startDate: dateRange[0].endOf('day').toDate(),
          endDate: dateRange[1].endOf('day').toDate(),
          status,
          adType,
          facebookPageIds,
          googleAdvertiserIds,
          categories
      });
    }
};

  const handleKeywordSearch = (event: ChangeEvent<HTMLInputElement>) => {
    competitorDispatch(setCompetitorAdUnits({...competitorAdUnits, searchKeyword: event.target.value}))
  }

  const handleGridViewItemsCountChange = (event: React.MouseEvent<HTMLElement>, newItems: number) => {
    competitorDispatch(setCompetitorAdUnits({ ...competitorAdUnits, gridViewItemsCount: newItems, loading: false }))
  }

  const handleStatusChange = (event: SelectChangeEvent<CompetitorAdStatus>) => {
    getCompetitorAdsUsingParams({
      searchKeyword,
      startDate,
      endDate,
      status: event.target.value,
      adType,
      facebookPageIds,
      googleAdvertiserIds,
      categories
  });  }

  const handleAdTypeChange = (event: SelectChangeEvent<MetaCompetitorAdType>) => {
    getCompetitorAdsUsingParams({
      searchKeyword,
      startDate,
      endDate,
      status,
      adType: event.target.value,
      facebookPageIds,
      googleAdvertiserIds,
      categories
  });  }

  return (
    <Box sx={{ margin: '50px' }}>
      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '36px' }}>
        <Box sx={{ width: '50%' }}>
          <Typography variant='h1' sx={desktop_h1}>Competitor Creatives</Typography>
        </Box>
        {/* Date picker */}
        <Box sx={{ alignItems: 'center', minWidth: '280px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <Box sx={{}}>
            {!loading && <Box sx={{ fontSize: '0.9em' }}>{creatives.creatives.length} of {creatives.creatives.length} ad units</Box>}
          </Box>
          <DateRangePickerMui startDate={startDate} endDate={endDate} onChange={handleDateRangeChange} onClose={() => ''} />
        </Box>
      </Grid>

      <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', mb: '36px', mt: '36px', display: 'flex', rowGap: '16px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
          <Channels handleAdsSearchBtnClick={() => getCompetitorAds()} />
          {['meta'].some(i => categories.includes(i)) && <CompetitorFacebookPagesFilter handleAdsSearchBtnClick={() => getCompetitorAds()}/>}
          {["YOUTUBE", "SEARCH", "PLAY", "SHOPPING", "MAPS"].some(i => categories.includes(i)) && <GoogleAdvertisersFilter handleAdsSearchBtnClick={() => getCompetitorAds()}/>}
          {/* Hide ad category and status filters for now */}
          {/*<Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Ad Category</InputLabel>
              <Select size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={adType}
                label="Ad Category"
                onChange={handleAdTypeChange}
              >
                {convertObjectToArray(MetaCompetitorAdType).map((i: MetaCompetitorAdType) => <MenuItem key={i} value={i}>{beautifyString(i)}</MenuItem>)}
              </Select>
            </FormControl>
          </Box>
 
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select size='small'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                label="Status"
                onChange={handleStatusChange}
              >
                <MenuItem value={"ALL"}>ALL</MenuItem>
                <MenuItem value={"ACTIVE"}>ACTIVE</MenuItem>
                <MenuItem value={"INACTIVE"}>INACTIVE</MenuItem>
              </Select>
            </FormControl>
          </Box>*/}
    
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>

          <Box>
            <GridViewItemsCountToggle items={gridViewItemsCount} handleChange={handleGridViewItemsCountChange} />
          </Box>
        </Box>
      </Grid>

      {true && <Grid container sx={{ alignItems: 'center', justifyContent: 'space-between', marginBottom: '36px' }}>
        <Box sx={{ alignItems: 'center', width: '50%' }}>
          <KeywordSearch keyword={searchKeyword} onChange={handleKeywordSearch} handleAdsSearchBtnClick={() => getCompetitorAds()} />
        </Box>

        <Box sx={{ display: 'flex', gap: '20px', justifyContent: 'flex-end', alignItems: 'center', width: '50%' }}>
         
        </Box>
      </Grid>}


      <>
        {loading && <CircularProgressLoading />}

        {!loading && competitorAdUnits.creatives.creatives.length === 0 && 
        <>
          <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${1}, 1fr)`, gap: '32px 24px' }} > 
            <Typography>Creatives not found for {selected?.name}.</Typography>
          </Box>
        </>}
       
        {!loading && competitorAdUnits.creatives.creatives.length > 0 && 
        <>
          <Box sx={{ display: 'grid', gridTemplateColumns: `repeat(${gridViewItemsCount}, 1fr)`, gap: '32px 24px' }} > 
            {creatives.creatives.filter(c => c.url).map((creative: CompetitorCreative) => <CompetitorCreativePreview key={creative.id} competitorCreative={creative}/>)}       
          </Box>
        </>}
      </>
      
    </Box>)

}
export default CompetitorCreatives

const totalContainerStyles = {
  marginTop: '1.0em',
  width: '100%',
  justifyContent: 'center',
  alignContent: 'center',
  display: 'flex'
}

const loadMoreBtnStyles = {
  cursor: 'pointer',
  borderRadius: '24px',
  backgroundColor: '#f2f2f2',
  textAlign: 'center',
  maxWidth: '7em',
  minWidth: '7em',
  alignSelf: 'center',
  padding: '5px',
  '&:hover': {
    backgroundColor: '#D3D3D3'
  }
}
