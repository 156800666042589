import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './App.css'
import ProtectedRoute from './components/ProtectedRoute';
import { PredictionView } from './components/Prediction';
import { AuthProvider } from './context/auth/AuthContext';
import LoginPage from './components/Login';
import { CustomersProvider } from './context/customer/CustomerContext';
import KivaAnalytics from './components/KivaAnalytics';
import { AdUnitView } from './components/AdUnits';
import { AdminView } from './components/Admin';
import { PersonView } from './components/PersonView';
import PostBoosterView from './components/meta/PostBoosterPage'
import MetaAdAutomationView from './components/meta/AdAutomationsPage'
import { MetaProvider } from './context/meta/MetaContext';
import { AlertProvider } from './context/alert/AlertContext';
import { SharedProvider } from './context/shared/SharedContext';
import MetaReducer from './context/meta/MetaReducer';
import AlertReducer from './context/alert/AlertReducer';
import KeywordAnalysisView from './components/KeywordAnalysisView';
import CreateBoostingRule from './components/meta/PostBoosterPage/CreateBoostingRule';
import CreateAdAutomation from './components/meta/AdAutomationsPage/CreateAdAutomation';
import { ManagedAdsView } from './components/ManagedAdsView';
import SharedReducer from './context/shared/SharedReducer';
import { AdsSpendReportingView } from './components/AdsSpendReportingView';
import { DigitalMarketingAssistantView } from './views/DigitalMarketingAssistantView';
import { CssView } from './views/CssView';
import LandingPage from './components/LandingPage';
import { AdTemplateView } from './components/AdTemplateView/AdTemplateView';
import { AdsProvider } from './context/ads/AdsContext';
import AdsReducer from './context/ads/AdsReducer';
import ContactUsView from './views/ContactUsView';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { primaryColors } from './styles/colors';
import ProfileView from './views/ProfileView';
import { AdminProvider } from './context/admin/AdminContext';
import AdminReducer from './context/admin/AdminReducer';
import { AnalyticsView } from './components/Analytics/AnalyticsView';
import PreviewPage from './components/PreviewPage';
import { CustomAssistantView } from './components/CustomAssistantView/CustomAssistantView';
import CssLandingPage from './components/Css/CssLandingPage';
import NotFoundPage from './components/NotFoundPage';
import CompetitorAdsView from './components/Competitors';
import { CompetitorProvider } from './context/competitors/CompetitorContext';
import CompetitorReducer from './context/competitors/CompetitorReducer';
import { FilesView } from './views/FilesView';
import KeywordPlannerView from './components/KeywordPlanner';
import UserDataDeletionPage from './components/meta/UserDataDeletionPage';


function App() {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <AlertProvider reducer={AlertReducer}>
          <AdminProvider reducer={AdminReducer}>
            <SharedProvider reducer={SharedReducer}>
              <CustomersProvider>
                <AdsProvider reducer={AdsReducer}>
                <CompetitorProvider reducer={CompetitorReducer}>
                  <MetaProvider reducer={MetaReducer}>
                    <Router>
                      {/*<Header />*/}
                      <Switch>
                        <Route path="/order">
                          <LandingPage />
                        </Route>
                        <Route path="/css">
                          <CssLandingPage />
                        </Route>
                        <Route path="/login">
                          <LoginPage />
                        </Route>
                        <ProtectedRoute path="/admin">
                          <AdminView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/kiva-analytics">
                          <KivaAnalytics />
                        </ProtectedRoute>
                        <ProtectedRoute path="/adunits">
                          <AdUnitView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/sales">
                          <PredictionView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/post-booster/create">
                          <CreateBoostingRule />
                        </ProtectedRoute>
                        <ProtectedRoute path="/post-booster">
                          <PostBoosterView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/meta-ad-automations/create">
                          <CreateAdAutomation />
                        </ProtectedRoute>
                        <ProtectedRoute path="/meta-ad-automations">
                          <MetaAdAutomationView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/ad-templates">
                          <AdTemplateView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/managed-ads">
                          <ManagedAdsView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/ads-spend-reporting">
                          <AdsSpendReportingView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/keyword-analysis">
                          <KeywordAnalysisView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/digital-marketing-assistant">
                          <DigitalMarketingAssistantView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/kiva-css">
                          <CssView />
                        </ProtectedRoute>
                        <ProtectedRoute exact path="/contact-us">
                          <ContactUsView />
                        </ProtectedRoute>
                        <ProtectedRoute exact path="/profile">
                          <ProfileView />
                        </ProtectedRoute>
                        <ProtectedRoute exact path="/analytics">
                          <AnalyticsView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/custom-assistant">
                          <CustomAssistantView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/files">
                          <FilesView />
                        </ProtectedRoute>
                        <Route path="/preview">
                          <PreviewPage />
                        </Route>
                        <Route path="/meta/user-data-deletion">
                          <UserDataDeletionPage />
                        </Route>
                        <ProtectedRoute path="/competitors/creatives">
                          <CompetitorAdsView />
                        </ProtectedRoute>
                        <ProtectedRoute path="/keyword-planner">
                          <KeywordPlannerView />
                        </ProtectedRoute>
                        <ProtectedRoute exact path="/">
                          <PersonView />
                        </ProtectedRoute>
                        <Route>
                          <NotFoundPage />
                        </Route>
                      </Switch>
                    </Router>
                  </MetaProvider>
                </CompetitorProvider>
                </AdsProvider>
              </CustomersProvider>
            </SharedProvider>
          </AdminProvider>
        </AlertProvider>
      </ThemeProvider>
    </AuthProvider>
  );
}

export default App;

// Global theme
const theme = createTheme({
  palette: {
    primary: {
      light: primaryColors[100],
      main: primaryColors[500],
      dark: primaryColors[600]
    },
    secondary: {
      light: primaryColors[100],
      main: primaryColors[300],
      dark: primaryColors[600]
    }
  },
  typography: {
    allVariants: {
      fontFamily: 'Montserrat-Regular, Arial, Helvetica, sans-serif',
      textTransform: 'none',
      fontWeight: 400,
      color: '#000'
    },
    h1: {
      fontFamily: 'Montserrat-Bold, Arial, Helvetica, sans-serif',
    },
    h2: {
      fontFamily: 'Montserrat-Bold, Arial, Helvetica, sans-serif',
    },
    h3: {
      fontFamily: 'Montserrat-Bold, Arial, Helvetica, sans-serif',
    },
    h4: {
      fontFamily: 'Montserrat-Bold, Arial, Helvetica, sans-serif',
    },
    h5: {
      fontFamily: 'Montserrat-Bold, Arial, Helvetica, sans-serif',
    },
    h6: {
      fontFamily: 'Montserrat-Bold, Arial, Helvetica, sans-serif',
    },
    button: {
      textTransform: "none",
      color: '#000'
    }
  },
});
